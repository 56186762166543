import MediaTrigger from 'media-trigger';
import { lock, unlock } from 'tua-body-scroll-lock';
import ScrollTrigger from './utils/ScrollTrigger';
import LiveWinners from './utils/LiveWinners';

const App = {
  mediaTrigger: null,
  scrollTrigger: null,
  liveWinners: null,
  drawerHandler: null,
  scrollLock: null,

  initMediaTrigger() {
    const navMain = document.querySelector('.nav-main');
    const navDrawer = document.querySelector('.nav-drawer');
    const headerNav = document.querySelector('.header__nav');

    this.mediaTrigger = new MediaTrigger({
      media: '(min-width: 992px)',
      entry: () => {
        if (navMain && headerNav) {
          headerNav.appendChild(navMain);

          if (this.liveWinners) {
            this.liveWinners.init();
          }
        }
      },
      exit: () => {
        if (navMain && navDrawer) {
          navDrawer.insertAdjacentElement('afterbegin', navMain);

          if (this.liveWinners) {
            this.liveWinners.destroy();
          }
        }
      },
    });

    this.mediaTrigger.init();
  },

  initLiveWinners() {
    this.liveWinners = new LiveWinners();
  },

  initScrollTrigger() {
    this.scrollTrigger = new ScrollTrigger();
    this.scrollTrigger.init();
  },

  initDrawerHandler() {
    const hamburger = document.querySelector('.hamburger');
    const navDrawer = document.querySelector('.nav-drawer');

    if (hamburger) {
      hamburger.addEventListener('click', () => {
        const { body } = document;

        if (navDrawer instanceof HTMLElement) {
          if (body.classList.contains('is-drawer')) {
            body.classList.remove('is-drawer');
            hamburger.setAttribute('aria-expanded', 'false');
            unlock(navDrawer);
          } else {
            body.classList.add('is-drawer');
            hamburger.setAttribute('aria-expanded', 'true');
            lock(navDrawer);
          }
        }
      });
    }
  },

  async init() {
    this.initLiveWinners();
    this.initMediaTrigger();
    this.initScrollTrigger();
    this.initDrawerHandler();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
