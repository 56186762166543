class LiveWinners {
  #container;

  #items;

  #minInterval;

  #maxInterval;

  #rafId;

  constructor(
    minInterval = 1000,
    maxInterval = 5000,
  ) {
    this.#container = document.querySelector('.live-winners');
    this.#items = Array.from(this.#container.querySelectorAll('.live-winners__item'));
    this.#minInterval = minInterval;
    this.#maxInterval = maxInterval;
    this.#rafId = null;
  }

  start() {
    this.#shiftItems();
    this.#scheduleNextShift();
  }

  #getRandomInterval() {
    return Math.floor(Math.random() * (this.#maxInterval - this.#minInterval + 1)) + this.#minInterval;
  }

  #scheduleNextShift() {
    this.#rafId = setTimeout(() => {
      this.#shiftItems();
      this.#scheduleNextShift();
    }, this.#getRandomInterval());
  }

  #shiftItems() {
    this.#items.forEach((item) => {
      const newItem = item;

      newItem.style.transform = 'translateY(100%)';
    });

    setTimeout(() => {
      const lastItem = this.#items.pop();

      lastItem.classList.add('fade-in');

      this.#container.prepend(lastItem);
      this.#items.unshift(lastItem);

      this.#items.forEach((item) => {
        const newItem = item;

        newItem.style.transition = 'none';
        newItem.style.transform = 'none';
      });

      this.#container.getBoundingClientRect();

      this.#items.forEach((item) => {
        const newItem = item;

        newItem.style.transition = '';
      });

      setTimeout(() => {
        const newItem = lastItem;

        newItem.classList.remove('fade-in');
      }, 175);
    }, 625);
  }

  init() {
    if (this.#items.length) {
      this.start();
    }
  }

  destroy() {
    if (this.#rafId) {
      clearTimeout(this.#rafId);

      this.#rafId = null;
    }

    this.#items.forEach((item) => {
      const newItem = item;

      newItem.style.transition = '';
      newItem.style.transform = '';
    });
  }
}

export default LiveWinners;
