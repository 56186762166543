class ScrollTrigger {
  constructor() {
    this.scrollTrigger = document.createElement('div');
    this.scrollTrigger.classList.add('scroll-trigger');

    document.body.appendChild(this.scrollTrigger);
  }

  init() {
    if (this.scrollTrigger) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            document.body.classList.remove('is-scrolled');
          } else {
            document.body.classList.add('is-scrolled');
          }
        },
        {
          root: null,
          threshold: 0,
          rootMargin: '-50px 0px 0px 0px',
        },
      );

      observer.observe(this.scrollTrigger);
    }
  }
}

export default ScrollTrigger;
